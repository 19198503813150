class SlideModel {
	id;
	children;

	constructor({
		id = '',
		children = '',
	}) {
		this.id = id;
		this.children = children;
	}
}

export default SlideModel;
