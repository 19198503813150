import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import cn from 'classnames';

import icClockNoBg from '../../assets/icons/ic-clock-nobg.svg';

import './card.scss';

const isOpen = status => status === 'OPEN';

const Card = ({ index, challenge, totalItems }) => (
		<div
			className={cn(
				'card',
				{ first: index === 0 },
				{ last: index === totalItems - 1 },
			)}
		>
			{challenge.image && (
				<div
					className="image"
					style={{ backgroundImage: `url('${challenge.image}')` }}
				/>
			)}
			<div className="content">
				{challenge.title && (
					<h3 className="card-title" style={{ WebkitBoxOrient: 'vertical' }}>
						{challenge.title}
					</h3>
				)}
				{challenge.description && (
					<p
						className="card-description"
						style={{ WebkitBoxOrient: 'vertical' }}
					>
						{challenge.description}
					</p>
				)}
				<div className="card-row">
					{challenge.price && (
						<span className="card-price">{`€ ${challenge.price}`}</span>
					)}
					{challenge.deadline && (
						<span className="card-date">
							<img src={icClockNoBg} alt="" />

							{isOpen(challenge.status) &&
								challenge.deadline &&
								moment(challenge.deadline).fromNow()}
							{!isOpen(challenge.status) && (
								<FormattedMessage
									id={`challenge.status_${challenge.status.toLowerCase()}`}
								/>
							)}
						</span>
					)}
				</div>
				<a
					className="card-link"
					href={`${process.env.REACT_APP_PLATFORM_URL}/challenges/${
						challenge.id
					}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<FormattedMessage id="home.challenges.button">
						{txt => <Fragment>{txt}</Fragment>}
					</FormattedMessage>
				</a>
			</div>
		</div>
	);

export default Card;
