import defaultChallengeImage from '../assets/images/challenge-default-images/challenge-default-image.png';

class ChallengeModel {
	id;
	title;
	description;
	status;
	image;
	price;
	deadline;
	url;
	areas;
	details;
	expectedSolutions;

	constructor({
		id,
		title,
		description,
		status,
		backgroundImg,
		award,
		deadline,
		url,
		areas,
		details,
		expectedSolutions = [],
	}) {
		this.id = id;
		this.title = title;
		this.status = status;
		this.description = description;
		this.image = backgroundImg || defaultChallengeImage;

		if (award && award.amount) {
			this.price = award.amount;
		}

		this.deadline = deadline;
		this.url = url;
		this.areas = areas;
		this.details = details;
		this.expectedSolutions = expectedSolutions;
	}
}

export default ChallengeModel;
