import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import notImplementedImage from '../../assets/illustrations/not-implemented.png';

import './not-implemented.scss';

const PageNotImplemented = ({ title }) => (
	<div className="not-implemented-wrapper">
		{title && <h1>{title}</h1>}
		<img src={notImplementedImage} alt="Not implemented" />
		<div className="center">
			<FormattedMessage id="not-implemented.not-implemented-message" />
		</div>
	</div>
);

export default injectIntl(PageNotImplemented);
