class SuccessStoryModel {
	id;
	logo;
	title;
	description;
	testimony;
	attestant;
	attestantJobRole;

	constructor({
		_id = '',
		logo = '',
		title = '',
		description = '',
		testimony = '',
		attestant = '',
		attestantJobRole = '',
	}) {
		this.id = _id;
		this.logo = logo;
		this.title = title;
		this.description = description;
		this.testimony = testimony;
		this.attestant = attestant;
		this.attestantJobRole = attestantJobRole;
	}
}

export default SuccessStoryModel;
