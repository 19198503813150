import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './buttons.scss';

const PrimaryButton = ({ children, className, ...props }) => (
	<Link className={`btn btn-primary ${className || ''}`} {...props}>
		{children}
	</Link>
);

const SecondaryButton = ({ children, className, ...props }) => (
	<Link className={`btn btn-secondary ${className || ''}`} {...props}>
		{children}
	</Link>
);

const TertiaryButton = ({
	children, fullWidth, outline, small, big, className, onClick, href,
}) => (
	<a
		href={href}
		onClick={onClick}
		className={cn(
			'tertiary-button',
			{
				'w-100': fullWidth,
			},
			{
				'outline-button': outline,
			},
			{
				small,
			},
			{
				big,
			},
			className,
		)}
	>
		{children}
	</a>
);

const Button = ({ children, className, ...props }) => (
	<Link className={`btn ${className || ''}`} {...props}>
		{children}
	</Link>
);

const SubmitButton = ({ children, className, ...props }) => (
	<button type="submit" className={`btn ${className || ''}`} {...props}>
		{children}
	</button>
);

const RoundButton = ({
	primary, children, className, small,
}) => (
	<div className={cn(className, 'round-button', { primary }, { small })}>
		{children}
	</div>
);

export {
	PrimaryButton, SecondaryButton, TertiaryButton, Button, SubmitButton, RoundButton,
};
