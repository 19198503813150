import React from 'react';
import { FormattedMessage } from 'react-intl';

import './success-story-card.scss';
import { PrimaryButton } from '../../components/ui-toolkit/buttons.js';

const lineClamp = {
	WebkitBoxOrient: 'vertical',
};

const SuccessStoryCard = ({ story, flipped }) => (
	<div className={`flip-on-hover${flipped ? ' flipped' : ''}`}>
		<div className="story-card">
			<div className="face front">
				<div className="image-content">
					{story.logo.path && (
						<img
							src={process.env.REACT_APP_API_ENDPOINT + story.logo.path}
							alt=""
						/>
					)}
					{!story.logo.path && (
						<div className="no-story-image">{story.title}</div>
					)}
				</div>
			</div>
			<div className="face back">
				<div className="image-content">
					{story.logo.path && (
						<img
							src={process.env.REACT_APP_API_ENDPOINT + story.logo.path}
							alt=""
						/>
					)}
					{!story.logo.path && (
						<div className="no-story-image">{story.title}</div>
					)}
				</div>
				<p className="testimony with-quotes" style={lineClamp}>
					{story.testimony}
				</p>
				<p className="attestant ellipsis-line-sm">{story.attestant}</p>
				<p className="attestantJobRole ellipsis-line-sm">
					{story.attestantJobRole}
				</p>
				<PrimaryButton
					className="ellipsis-line-sm btn-success-stories-card"
					to={`/success-stories/${story.id}`}
				>
					<FormattedMessage id="button.readMore" />
				</PrimaryButton>
			</div>
		</div>
	</div>
);

export default SuccessStoryCard;
