import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ScrollToTop from '../../shared/utils.js';
import TestimonialCard from './testimonial-card.component.js';

import iconClose from '../../assets/icons/ic-cross.svg';

import './testimonials.scss';

class TestimonialsComponent extends React.Component {
	constructor(props) {
		super(props);

		this.listStart = React.createRef();
		this.listEnd = React.createRef();
		this.list = React.createRef();
		this.video = React.createRef();

		this.state = {
			hideButtonPrev: true,
			hideButtonNext: true,
			isVideoOpen: false,
			videoUrl: null,
		};
	}

	componentDidMount() {
		ScrollToTop();
		this.listStartObserver = new IntersectionObserver((entries) => {
			const buttonPrev = entries[0];

			if (!buttonPrev.isIntersecting) {
				this.setState({ hideButtonPrev: false });
			} else {
				this.setState({ hideButtonPrev: true });
			}
		});

		this.listEndObserver = new IntersectionObserver((entries) => {
			const buttonNext = entries[0];

			if (!buttonNext.isIntersecting) {
				this.setState({ hideButtonNext: false });
			} else {
				this.setState({ hideButtonNext: true });
			}
		});

		this.listStartObserver.observe(this.listStart.current);
		this.listEndObserver.observe(this.listEnd.current);
	}

	componentWillUnmount() {
		this.listStartObserver.unobserve(this.listStart.current);
		this.listEndObserver.unobserve(this.listEnd.current);
	}

	handleSliderNavigationPrev = () => {
		this.list.current.scrollBy({
			left: -320,
			behavior: 'smooth',
		});
	};

	handleSliderNavigationNext = () => {
		this.list.current.scrollBy({
			left: 320,
			behavior: 'smooth',
		});
	};

	openVideo = (newUrl) => {
		this.setState({
			isVideoOpen: true,
			videoUrl: newUrl,
		});
	};

	closeVideo = () => {
		this.setState({
			isVideoOpen: false,
		});
		this.video.current.pause();
	};

	render() {
		const {
			hideButtonPrev,
			hideButtonNext,
			isVideoOpen,
			videoUrl,
		} = this.state;
		const { testimonials = [] } = this.props;

		return (
			<section
				className={cn('active-challenges testimonials', {
					'is-video-open': isVideoOpen,
				})}
			>
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-12 center-xs middle-xs">
							<FormattedMessage id="home.testimonials.title">
								{title => <h2 className="title">{title}</h2>}
							</FormattedMessage>
						</div>
					</div>
				</div>
				<div className="slider-wrapper-outer">
					<div className="controls">
						<button
							className={cn('prev', { hide: hideButtonPrev })}
							onClick={this.handleSliderNavigationPrev}
							type="button"
						/>
						<button
							className={cn('next', { hide: hideButtonNext })}
							onClick={this.handleSliderNavigationNext}
							type="button"
						/>
					</div>
					<div className="slider-wrapper-inner" ref={this.list}>
						<div
							className={cn('slider', {
								'two-cards': testimonials.length <= 2,
							})}
						>
							<div
								ref={this.listStart}
								style={{
									width: 0,
									height: 500,
									display: 'inline-block',
								}}
							/>
							{testimonials.map((testimonial, index) => (
								<TestimonialCard
									/* eslint-disable-next-line no-underscore-dangle */
									key={testimonial._id}
									totalItems={testimonials.length}
									testimonial={testimonial}
									index={index}
									openVideo={this.openVideo}
									isVideoOpen={isVideoOpen}
								/>
							))}
							<div
								ref={this.listEnd}
								style={{
									width: 0,
									height: 500,
									display: 'inline-block',
								}}
							/>
						</div>
					</div>
				</div>

				<div
					className={cn('video-wrapper', {
						'is-video-open': isVideoOpen,
					})}
				>
					<div className="video-container">
						{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
						{videoUrl && (
							<video
								className="video"
								src={videoUrl}
								typeof="video/mp4"
								controls
								autoPlay
								muted
								ref={this.video}
							/>
						)}
						<div
							className="close"
							style={{ backgroundImage: `url(${iconClose})` }}
							onClick={() => this.closeVideo()}
						/>
					</div>
				</div>
			</section>
		);
	}
}

export default TestimonialsComponent;
