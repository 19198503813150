import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

const Seo = data => (
	<Helmet>
		{/* General tags */}
		<title>{data.title}</title>
		<meta name="description" content={data.description || data.title} />
		{/* OpenGraph tags */}
		<meta name="og:url" content={`${process.env.REACT_APP_WEBSITE_ENDPOINT}${data.location.pathname}`} />
		<meta name="og:title" content={data.title} />
		<meta name="og:description" content={data.description || data.title} />
		<meta name="og:image" content={data.image} />
		<meta name="og:type" content="website" />
		{/* Twitter Card tags */}
		<meta name="twitter:title" content={data.title} />
		<meta name="twitter:description" content={data.description || data.title} />
		<meta name="twitter:image" content={data.image} />
		<meta name="twitter:card" content="summary" />
		{/* Microdata specs */}
		<script type="application/ld+json">{data.microdata}</script>
	</Helmet>
);

export default withRouter(Seo);
