import axios from 'axios';

import { logError } from '../../infrastructure/error-handling.js';
import ChallengeModel from '../../shared/challenge.model.js';

class HomePageService {
	getHomeData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/homePage?lang=${language}&populate=1`
			)
			.then(res => res)
			.catch(logError);
	}

	getAllChallenges(language) {
		const searchParams = new URLSearchParams({
			size: 15,
			status: 'OPEN,EVALUATION,CLOSED',
			lang: language === 'pt' ? 'pt_PT' : 'en_GB'
		});
		searchParams.append('sort', 'status,desc');
		searchParams.append('sort', 'deadline,asc');

		return axios
			.get(`${process.env.REACT_APP_PLATFORM_API_ENDPOINT}/api/challenge/all`, {
				params: searchParams
			})
			.then(res =>
				res.data.content.map(challenge => new ChallengeModel(challenge))
			)
			.catch(logError);
	}

	getTestimonials(language) {
		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/collections/get/testimonials`,
				{
					lang: language
				}
			)
			.then(({ data }) => data.entries)
			.catch(logError);
	}
}

export default new HomePageService();
