import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const ParallaxShape = ({
	imgPath, offsetYMin = '', offsetYMax = '', offsetXMin = '', offsetXMax = '',
}) => (
	<Parallax
		className="shape"
		offsetYMin={offsetYMin}
		offsetYMax={offsetYMax}
		offsetXMin={offsetXMin}
		offsetXMax={offsetXMax}
		slowerScrollRate
	>
		<img src={imgPath} alt="" />
	</Parallax>
);

export default ParallaxShape;
