import React, { Component } from 'react';
import Swipe from 'react-easy-swipe';

import './slider.scss';

class Slider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slides: props.slides,
			position: 0,
			unity: props.unity || 60,
			margin: props.margin || 20,
			canAnimate: true,
			swipeable: false,
			isAnimating: false,
			hasPagination: props.hasPagination,
		};
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.unity !== this.props.unity
			|| prevProps.margin !== this.props.margin
			|| prevProps.hasPagination !== this.props.hasPagination
			|| prevProps.slides !== this.props.slides

		) {
			this.setState({
				unity: this.props.unity || 60,
				margin: this.props.margin || 20,
				hasPagination: this.props.hasPagination,
				slides: this.props.slides,
			});
		}
	}

	componentWillUnmount() {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
			this.timerHandle = 0;
		}
	}

	onSwipeLeft() {
		const max = this.state.slides.length - 1;

		if (this.state.isAnimating || (max <= 2 && this.state.position === max)) {
			return;
		}

		if (this.state.swipeable) {
			this.moveTo(this.state.position + 1);
		}
	}

	onSwipeRight() {
		const max = this.state.slides.length - 1;

		if (this.state.isAnimating || (max <= 2 && this.state.position === 0)) {
			return;
		}

		if (this.state.swipeable) {
			this.moveTo(this.state.position - 1);
		}
	}

	onSwipeMove(position) {
		this.setState({
			swipeable: Math.abs(position.x) > 40,
		});

		return Math.abs(position.x) > 40;
	}

	handleOnClick(id, position, selected = false) {
		if (this.state.isAnimating) {
			return;
		}

		if (selected) {
			if (this.props.handleOnClick) {
				this.props.handleOnClick(id);
			}
			return;
		}

		let newPosition = position;

		const max = this.state.slides.length - 1;

		if (max > 2) {
			if (
				newPosition === 0
				&& this.state.position === this.state.slides.length - 1
			) {
				newPosition = this.state.slides.length;
			} else if (
				newPosition === this.state.slides.length - 1
				&& this.state.position === 0
			) {
				newPosition = -1;
			}
		}

		this.moveTo(newPosition);
	}

	moveTo(position) {
		this.setState((prevState) => {
			if (position >= prevState.slides.length) {
				this.timerHandle = setTimeout(() => {
					this.setState({
						position: 0,
						canAnimate: false,
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			} else if (position < 0) {
				this.timerHandle = setTimeout(() => {
					this.setState({
						position: prevState.slides.length - 1,
						canAnimate: false,
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			} else {
				this.timerHandle = setTimeout(() => {
					this.setState({
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			}

			return {
				position,
				canAnimate: true,
				isAnimating: true,
			};
		});
	}

	calcPositionSlide(index) {
		const max = this.state.slides.length - 1;
		if (this.state.position <= 0) {
			if (index === max && max > 2) {
				return -this.state.unity;
			} if (this.state.slides.length > 3 && index === max - 1) {
				return -(this.state.unity * 2);
			}
		} else if (this.state.position >= max && max > 2) {
			if (index === 0) {
				return this.state.unity * this.state.slides.length;
			} if (this.state.slides.length > 3 && index === 1) {
				return this.state.unity * (this.state.slides.length + 1);
			}
		}

		return this.state.unity * index;
	}

	isSelected(index) {
		const max = this.state.slides.length - 1;
		if (this.state.position < 0 && index === this.state.slides.length - 1) {
			return true;
		} if (this.state.position > max && index === 0) {
			return true;
		}

		return index === this.state.position;
	}

	render() {
		if (!this.state.slides) return null;
		const {
			unity, margin, slides, hasPagination,
		} = this.state;
		const position = -(this.state.position * unity - margin);

		return (
			<div className="inc-mobile-slider">
				<Swipe
					className="slider"
					allowMouseEvents
					onSwipeLeft={() => this.onSwipeLeft()}
					onSwipeRight={() => this.onSwipeRight()}
					onSwipeMove={pos => this.onSwipeMove(pos)}
				>
					<div
						className={`slides${this.state.canAnimate ? ' animated' : ''}`}
						style={{ transform: `translateX(${position}%)` }}
					>
						{slides.map((slide, index) => (
							<div
								key={slide.id}
								className={`slide${this.isSelected(index) ? ' selected' : ''}`}
								style={{
									left: `${this.calcPositionSlide(index)}%`,
									width: `${unity}%`,
								}}
								onClick={() => this.handleOnClick(slide.id, index, this.isSelected(index))
								}
							>
								{slide.children}
							</div>
						))}
					</div>
				</Swipe>
				{
					hasPagination && slides.length > 1 && (
						<div className="container container-fluid">
							<div className="row">
								<div className="col-xs-12 center-xs">
									<div className="pagination">
										{slides.map((slide, index) => (
											<div
												key={slide.id}
												className={`circle${
													this.isSelected(index) ? ' selected' : ''
												}`}
												onClick={() => this.handleOnClick(
													slide.id,
													index,
													this.isSelected(index),
												)
												}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}

export default Slider;
