import React, { Fragment } from 'react';
import Media from 'react-media';
import classNames from 'classnames';
import CountUp from 'react-countup';
import onClickOutside from 'react-onclickoutside';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { FormattedMessage, injectIntl } from 'react-intl';

import HomePageService from './home.service.js';
import { actions } from '../../shared/store.js';
import ParallaxShape from '../../components/parallax-shape/parallax-shape.js';
import { Button } from '../../components/ui-toolkit/buttons.js';
import NotImplemented from '../../components/not-implemented/page-not-implemented.js';
import illuHomeHeader from '../../assets/illustrations/illu-header-home-page.svg';
import ScrollToTop from '../../shared/utils.js';
import Slider from '../../components/slider/slider.js';
import SlideModel from '../../components/slider/slide.model.js';
import Card from '../../components/card/card.component.js';
import SuccessStoryModel from '../success-stories/success-stories.model.js';
import SuccessStoryCard from '../success-stories/success-story-card.component.js';
import TestimonialsComponent from './testimonials.component.js';
import Seo from '../../infrastructure/seo.js';

import icLightbulb from '../../assets/icons/ic-lightbulb.svg';
import icClock from '../../assets/icons/ic-clock.svg';
import icSuccess from '../../assets/icons/ic-success.svg';
import shape1 from '../../assets/illustrations/shape1.svg';
import shape4 from '../../assets/illustrations/shape4.svg';
import shape5 from '../../assets/illustrations/shape5.svg';

import './home.scss';

class HomePage extends React.Component {
	static prefetchData = async language => Promise.all([
		HomePageService.getHomeData(language),
		HomePageService.getAllChallenges(language),
		HomePageService.getTestimonials(language),
	]);

	state = {
		mainSuccessStories: null,
		hasError: false,
	};

	componentDidMount() {
		const { homePageData, testimonials, setLoading } = this.props;

		if (!homePageData || !testimonials) {
			this.fetchData();
		} else {
			setLoading(false);

			setTimeout(() => {
				this.setState({
					mainSuccessStories: homePageData.main_success_stories,
				});
			}, 0);
		}
	}

	componentDidUpdate(prevProps) {
		const { language } = this.props;

		if (prevProps.language.value !== language.value) {
			this.fetchData();
		}
	}

	handleModal = () => {
		const { homePageData, toggleModal } = this.props;

		if (homePageData.video) {
			toggleModal();
		}
	};

	closeModal = () => {
		const { closeModal } = this.props;

		closeModal();
	};

	fetchData() {
		const {
			language,
			setHomePageData,
			setTestimonials,
			setActiveChallengesData,
			setLoading,
		} = this.props;

		setLoading(true);

		HomePage.prefetchData(language.value)
			.then(([{ data }, res, testimonials]) => {
				setHomePageData(data);
				setTestimonials(testimonials);
				setActiveChallengesData(res);
				setLoading(false);

				this.setState({
					mainSuccessStories: data.main_success_stories,
				});
			})
			.catch(() => {
				setLoading(false);
				this.setState({
					hasError: true,
				});
			});
	}

	render() {
		const {
			homePageData,
			testimonials,
			activeChallengesData,
			modalOpen,
			intl: { formatMessage },
			addresses,
			phones,
			generalData,
		} = this.props;
		const { hasError, mainSuccessStories } = this.state;

		if (hasError) {
			return <NotImplemented title="Homepage" />;
		}

		if (!homePageData) {
			return null;
		}

		/* eslint-disable camelcase */
		const {
			header_title,
			main_success_stories_title,
			activity_areas_title,
			seeker_title,
			solver_title,
			active_challenges_title,
			stats_title,
			video,
			partnerTitle,
			partners,
		} = homePageData;

		const cards = activeChallengesData.map(
			(challenge, index) => new SlideModel({
				id: challenge.id,
				children: (
					<Card
						index={index}
						challenge={challenge}
						totalItems={activeChallengesData.length}
					/>
				),
			}),
		);

		return (
			<div className="home-page">
				<Seo
					title={formatMessage({ id: 'navigation.homePage' })}
					description={(homePageData && homePageData.header_description) || 'Inocrowd'}
					image={`${process.env.REACT_APP_WEBSITE_ENDPOINT}${illuHomeHeader}`}
					microdata={`{
									"@context": "https://schema.org",
									"@graph": [
										{
											"@type": "WebApplication",
											"name": "Inocrowd",
											"url": "${process.env.REACT_APP_WEBSITE_ENDPOINT}",
											"applicationCategory": "BusinessApplication",
											"operatingSystem": "Windows, Linux, OSX, iOS, Android",
											"sameAs": [
												"${process.env.REACT_APP_PLATFORM_ENDPOINT}",
												"https://www.facebook.com/InoCrowd/",
												"https://www.linkedin.com/company/inocrowd",
												"https://twitter.com/InoCrowd",
												"https://www.instagram.com/inocrowd",
												"https://www.youtube.com/channel/UC8JotJA9Txz_6NQXWuEwdvQ"
											],
											${homePageData && `
												"description": "${homePageData.header_description}",
											`}
											"image": "${process.env.REACT_APP_WEBSITE_ENDPOINT}${illuHomeHeader}",
											"provider": {
												"@type": "Organization",
												"name": "Inocrowd",
												${addresses.length > 0 && `
													"address":  "${addresses[0].description.replace(/\n/g, ' ')}",
												`}
												${phones.length > 0 && `
													"contactPoint": {
														"@type": "ContactPoint",
														"telephone": "${phones[0].phoneNumber}",
														"contactType": "customer service"
													},
													"telephone": "${phones[0].phoneNumber}",
												`}
												${generalData && generalData.logo ? `
													"logo": "${process.env.REACT_APP_API_ENDPOINT}${generalData.logo.path}"
												` : ''}
											},
											${homePageData && homePageData.activity_areas && `
												"audience": [
													${homePageData.activity_areas.map(item => `{
														"@type": "Audience",
														"audienceType": "${item.title}",
														"image": "${process.env.REACT_APP_API_ENDPOINT}${item.image.path}"
													}`)}
												],
											`}
											"featureList": ["${process.env.REACT_APP_WEBSITE_ENDPOINT}/how-it-works/seeker","${process.env.REACT_APP_WEBSITE_ENDPOINT}/how-it-works/solver"],
											${partners && partners.length > 0 && `
												"sponsor": [
													${partners.map(item => `{
														"@type": "Organization",
														"name": "${item.value.name}",
														"logo": "${process.env.REACT_APP_API_ENDPOINT}${item.value.logo.path}",
														"url": "${item.value.link}"
													}`)}
												],
											`}
											${activeChallengesData && activeChallengesData.length > 0 && `
												"offers": [
													${activeChallengesData.map(item => `{
														"@type": "Offer",
														"price": "0",
														"priceCurrency": "EUR",
														"itemOffered": {
															"@type": "Service",
															"name": "${item.title}",
															"description": "${item.description}",
															"award": "${item.price}",
															"hoursAvailable": {
																"@type": "OpeningHoursSpecification",
																"validThrough": "${item.deadline}"
															},
															${item.image && `
																"image": "${item.image}",
															`}
															"url": "${process.env.REACT_APP_PLATFORM_ENDPOINT}challenges/${item.id}"
														}
													}`)}
												]
											`}
										},
										{
											"@type": "BreadcrumbList",
											"itemListElement": [
												{
													"@type": "ListItem",
													"name": "Inocrowd",
													"position": 1,
													"item": "${process.env.REACT_APP_WEBSITE_ENDPOINT}"
												}
											]
										}
									]
								}`}
				/>

				{
					<Fragment>
						{header_title && (
							<Header data={homePageData} toggleModal={this.handleModal} />
						)}
						{testimonials && testimonials.length > 0
						&& (
							<TestimonialsComponent
								testimonials={testimonials}
							/>
						)
						}
						{main_success_stories_title && (
							<MainSuccessStories
								{...homePageData}
								successStories={mainSuccessStories}
							/>
						)}
						{activity_areas_title && <ActivityAreas data={homePageData} />}
						{seeker_title
							&& solver_title && <SeekerSolver data={homePageData} />}
						{active_challenges_title && (
							<div>
								<Media query="(max-width: 599px)">
									{matches => (matches ? (
										<div id="mobile-home-slider">
											<Slider slides={cards} unity={80} margin={10} />
										</div>
									) : (
										<ActiveChallenges
											title={active_challenges_title}
											challenges={activeChallengesData}
										/>
									))
									}
								</Media>
							</div>
						)}
						{stats_title && <Stats data={homePageData} />}
						{partners && (
							<section className="partners">
								<div className="container container-fluid center-xs">
									<h2 className="title">{partnerTitle}</h2>
									{partners.map((partner, i) => (
										<a
											className="partner"
											href={partner.value.link}
											target="_blank"
											rel="noopener noreferrer"
											key={i}
										>
											<img
												src={
													process.env.REACT_APP_API_ENDPOINT
													+ partner.value.logo.path
												}
												alt={partner.value.name}
											/>
										</a>
									))}
								</div>
							</section>
						)}
						{modalOpen
							&& video && (
							<div className="modal">
								<div className="video-wrapper">
									<Video data={video} closeModal={this.closeModal} />
								</div>
							</div>
						)}
					</Fragment>
				}
			</div>
		);
	}
}

// eslint-disable-next-line react/no-multi-comp
class VideoInternal extends React.Component {
	constructor(props) {
		super(props);

		this.videoRef = React.createRef();
	}

	handleClickOutside = (evt) => {
		const { closeModal } = this.props;

		if (evt.target !== this.videoRef) {
			closeModal();
		}
	};

	render() {
		const { data } = this.props;

		return (
			<video ref={this.videoRef} width="1200" controls autoPlay>
				<source
					src={`${process.env.REACT_APP_API_ENDPOINT}/${data}`}
					type="video/mp4"
				/>
				{'Your browser does not support HTML5 video.'}
			</video>
		);
	}
}

const Video = onClickOutside(VideoInternal);

const Header = ({ data, toggleModal }) => (
	<header className="page-header">
		<div className="container container-fluid relative">
			<div className="row">
				<div className="col-xs-12 col-md-6 top-xs start-xs center-md col title">
					{data.header_title && <h1 className="title">{data.header_title}</h1>}
					{data.header_description && (
						<p className="description">{data.header_description}</p>
					)}
					<div className="buttons center-xs">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://platform.inocrowd.com.pt/sign-up"
							className="btn-hover-effect-2"
						>
							<FormattedMessage id="howItWorks.seeker.button">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://platform.inocrowd.com.pt/sign-up"
							className="btn-hover-effect"
						>
							<FormattedMessage id="howItWorks.solver.button">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</a>
					</div>
				</div>
				<div className="col-xs-12 col-md-6 top-xs center-xs media">
					<div className="image">
						<div className="img"/>
						<div
							className={`${classNames({ 'has-media': data.video })}`}
							onClick={toggleModal}
						/>
					</div>
				</div>
			</div>
		</div>
	</header>
);

const MainSuccessStories = ({ main_success_stories_title, successStories }) => {
	if (
		!main_success_stories_title
		|| !successStories
		|| successStories.length === 0
	) {
		return null;
	}

	const mainSuccessStories = successStories.map(
		story => new SuccessStoryModel(story),
	);

	const cards = mainSuccessStories.map(
		card => new SlideModel({
			id: card.id,
			children: <SuccessStoryCard story={card} />,
		}),
	);

	return (
		<section className="main-success-stories">
			<ParallaxShape imgPath={shape1} offsetYMin={-30} offsetYMax={30} />
			<div className="container container-fluid">
				<div className="row">
					<div className="col-xs-12 center-xs">
						<h2 className="title">{main_success_stories_title}</h2>
					</div>
				</div>
			</div>
			<Media query="(max-width: 599px)" defaultMatches={false}>
				{matches => (matches ? (
					<Slider slides={cards} unity={80} margin={10} />
				) : (
					<div className="container container-fluid">
						<div className="row stories-container">
							{mainSuccessStories.map(story => (
								<div className="col-xs-12 col-sm-4" key={story.id}>
									<SuccessStoryCard story={story} />
								</div>
							))}
						</div>
					</div>
				))
				}
			</Media>
		</section>
	);
};

const ActivityAreas = ({ data }) => {
	if (!data.activity_areas || data.activity_areas.length === 0) return null;

	return (
		<section className="activity-areas">
			<div className="container container-fluid">
				<div className="row">
					<div className="col-xs-12 center-xs">
						<h2 className="title">{data.activity_areas_title}</h2>
					</div>
				</div>
				<div className="row activities">
					{data.activity_areas.map(activity => (
						<div className="col-xs-12 col-sm-4 center-xs" key={activity._id}>
							<div className="activity">
								<div className="img-container">
									<img
										src={
											process.env.REACT_APP_API_ENDPOINT + activity.image.path
										}
										alt={activity.title}
									/>
								</div>
								<h5>{activity.title}</h5>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

const SeekerSolver = ({ data }) => (
	<section className="seeker-solver">
		<div className="container container-fluid">
			<div className="row">
				<div className="col-xs-12 col-md-6 top-xs start-xs center-md col">
					{data.seeker_image
						&& data.seeker_image.path && (
						<div className="media theme-1">
							<img
								src={
									process.env.REACT_APP_API_ENDPOINT + data.seeker_image.path
								}
								alt={data.seeker_image.meta && data.seeker_image.meta.title}
							/>
						</div>
					)}
				</div>
				<div className="col-xs-12 col-md-6 top-xs start-xs center-md col">
					<div className="content theme-2">
						<h2 className="title">{data.seeker_title}</h2>
						{data.seeker_description && (
							<p className="description">{data.seeker_description}</p>
						)}
						<Button className="btn-color-theme-2" to="/how-it-works/seeker">
							<FormattedMessage id="home.seeker.button">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 col-md-6 top-xs start-xs center-md col">
					<div className="content theme-1">
						<h2 className="title">{data.solver_title}</h2>
						{data.solver_description && (
							<p className="description">{data.solver_description}</p>
						)}

						<Button className="btn-color-theme-1" to="/how-it-works/solver">
							<FormattedMessage id="home.solver.button">
								{txt => <Fragment>{txt}</Fragment>}
							</FormattedMessage>
						</Button>
					</div>
				</div>
				<div className="col-xs-12 col-md-6 top-xs start-xs center-md col first-xs last-md">
					{data.solver_image
						&& data.solver_image.path && (
						<div className="media theme-2">
							<img
								src={
									process.env.REACT_APP_API_ENDPOINT + data.solver_image.path
								}
								alt={data.solver_image.meta && data.solver_image.meta.title}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	</section>
);

// eslint-disable-next-line react/no-multi-comp
class ActiveChallenges extends React.Component {
	constructor(props) {
		super(props);
		this.listStart = React.createRef();
		this.listEnd = React.createRef();
		this.list = React.createRef();

		this.state = {
			hideButtonPrev: true,
			hideButtonNext: true,
		};
	}

	componentDidMount() {
		ScrollToTop();
		this.listStartObserver = new IntersectionObserver((entries) => {
			const buttonPrev = entries[0];

			if (!buttonPrev.isIntersecting) {
				this.setState({ hideButtonPrev: false });
			} else {
				this.setState({ hideButtonPrev: true });
			}
		});

		this.listEndObserver = new IntersectionObserver((entries) => {
			const buttonNext = entries[0];

			if (!buttonNext.isIntersecting) {
				this.setState({ hideButtonNext: false });
			} else {
				this.setState({ hideButtonNext: true });
			}
		});

		this.listStartObserver.observe(this.listStart.current);
		this.listEndObserver.observe(this.listEnd.current);
	}

	componentWillUnmount() {
		this.listStartObserver.unobserve(this.listStart.current);
		this.listEndObserver.unobserve(this.listEnd.current);
	}

	handleSliderNavigationPrev = () => {
		this.list.current.scrollBy({
			left: -320,
			behavior: 'smooth',
		});
	};

	handleSliderNavigationNext = () => {
		this.list.current.scrollBy({
			left: 320,
			behavior: 'smooth',
		});
	};

	render() {
		const { hideButtonPrev, hideButtonNext } = this.state;
		const { title, challenges = [] } = this.props;

		return (
			<section className="active-challenges">
				<ParallaxShape imgPath={shape5} offsetYMin={-30} offsetYMax={30} />
				<div className="container container-fluid">
					<div className="row">
						<div className="col-xs-12 center-xs middle-xs">
							<h2 className="title">{title}</h2>
						</div>
					</div>
				</div>
				<div className="slider-wrapper-outer">
					<div className="controls">
						<button
							className={classNames('prev', { hide: hideButtonPrev })}
							onClick={this.handleSliderNavigationPrev}
							type="button"
						/>

						<button
							className={classNames('next', { hide: hideButtonNext })}
							onClick={this.handleSliderNavigationNext}
							type="button"
						/>
					</div>
					<div className="slider-wrapper-inner" ref={this.list}>
						<div
							className={classNames('slider', {
								'two-cards': challenges.length <= 2,
							})}
						>
							<div
								ref={this.listStart}
								style={{ width: 0, height: 500, display: 'inline-block' }}
							/>
							{challenges.map((challenge, index) => (
								<Card
									key={challenge.id}
									totalItems={challenges.length}
									challenge={challenge}
									index={index}
								/>
							))}
							<div
								ref={this.listEnd}
								style={{ width: 0, height: 500, display: 'inline-block' }}
							/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

// eslint-disable-next-line react/no-multi-comp
class Stats extends React.Component {
	constructor(props) {
		super(props);
		this.scene = React.createRef();

		this.state = {
			startCountup: false,
		};
	}

	componentDidMount() {
		this.sceneStartObserver = new IntersectionObserver((entries) => {
			const buttonPrev = entries[0];

			if (buttonPrev.isIntersecting) {
				this.setState({ startCountup: true });
			}
		});

		this.sceneStartObserver.observe(this.scene.current);
	}

	componentWillUnmount() {
		this.sceneStartObserver.unobserve(this.scene.current);
	}

	parseSafeInt(str) {
		const int = str
			.toString()
			.trim()
			.replace(/\s/g, '');
		return parseInt(int, 0);
	}

	parseSafeIntSuffix(str) {
		return str.toString().replace(/[0-9]/g, '');
	}

	render() {
		const { data } = this.props;
		const { startCountup } = this.state;

		return (
			<section className="stats">
				<ParallaxShape imgPath={shape4} offsetYMin={-20} offsetYMax={20} />
				<div className="container container-fluid">
					<div className="row center-xs">
						<div className="col-xs-12 center-xs middle-xs">
							<h2 className="title">{data.stats_title}</h2>
						</div>
					</div>
					<div className="row center-xs" ref={this.scene}>
						{data.stats_value_1
							&& data.stats_description_1 && (
							<div className="col-xs-12 col-md-4 center-xs middle-xs col item theme-1">
								<p className="value">
									<i
										className="icon"
										style={{ backgroundImage: `url('${icLightbulb}')` }}
									/>
									<span className="solvers-count-up">
										<CountUp
											end={this.parseSafeInt(data.stats_value_1)}
											duration={3}
											separator=" "
											suffix={this.parseSafeIntSuffix(data.stats_value_1)}
											redraw={!startCountup}
										>
											{({ countUpRef, start }) => {
												if (startCountup) {
													start();
												}
												return <span ref={countUpRef} />;
											}}
										</CountUp>
									</span>
								</p>
								<p className="description">{data.stats_description_1}</p>
							</div>
						)}
						{data.stats_value_2
							&& data.stats_description_2 && (
							<div className="col-xs-12 col-md-4 center-xs middle-xs col item theme-2">
								<p className="value">
									<i
										className="icon"
										style={{ backgroundImage: `url('${icClock}')` }}
									/>
									<span className="days">
										<CountUp
											end={this.parseSafeInt(data.stats_value_2)}
											duration={3}
											separator=" "
											suffix={this.parseSafeIntSuffix(data.stats_value_2)}
											redraw={!startCountup}
										>
											{({ countUpRef, start }) => {
												if (startCountup) {
													start();
												}
												return <span ref={countUpRef} />;
											}}
										</CountUp>
									</span>
								</p>
								<p className="description">{data.stats_description_2}</p>
							</div>
						)}
						{data.stats_value_3
							&& data.stats_description_3 && (
							<div className="col-xs-12 col-md-4 center-xs middle-xs col item theme-1">
								<p className="value">
									<i
										className="icon"
										style={{ backgroundImage: `url('${icSuccess}')` }}
									/>
									<span className="success-rate">
										<CountUp
											end={this.parseSafeInt(data.stats_value_3)}
											duration={3}
											separator=" "
											suffix={this.parseSafeIntSuffix(data.stats_value_3)}
											redraw={!startCountup}
										>
											{({ countUpRef, start }) => {
												if (startCountup) {
													start();
												}
												return <span ref={countUpRef} />;
											}}
										</CountUp>
									</span>
								</p>
								<p className="description">{data.stats_description_3}</p>
							</div>
						)}
					</div>
				</div>
			</section>
		);
	}
}

const mapToProps = ({ language, homePageData, activeChallengesData, testimonials, addresses, phones, generalData }) => ({
	language,
	homePageData,
	activeChallengesData,
	testimonials,
	addresses,
	phones,
	generalData,
});

export default injectIntl(
	connect(
		mapToProps,
		actions,
	)(HomePage),
);

export { HomePage };
