import React, { Component } from 'react';
import cn from 'classnames';

import defaultImage from '../../assets/illustrations/default-our-team.png';
import iconPlay from '../../assets/icons/ic-play-alt.svg';

import './testimonial-card.scss';

class TestimonialCard extends Component {
	state = {
		mouseOverCard: false,
	};

	render() {
		const {
			index, testimonial, totalItems, openVideo, isVideoOpen,
		} = this.props;
		const { mouseOverCard } = this.state;

		return (
			<div
				className={cn(
					'card video-card',
					{ first: index === 0 },
					{ last: index === totalItems - 1 },
					{ hover: mouseOverCard },
					{ 'open-video': isVideoOpen },
				)}
				onMouseEnter={() => this.setState({ mouseOverCard: true })}
				onMouseLeave={() => this.setState({ mouseOverCard: false })}
				onClick={() => { if (!isVideoOpen) { openVideo(`${process.env.REACT_APP_API_ENDPOINT}/${testimonial.videoUrl}`); } }}
			>
				{(testimonial.posterUrl && testimonial.posterUrl.path)
					? (
						<div className="poster" style={{ backgroundImage: `url(${process.env.REACT_APP_API_ENDPOINT}${testimonial.posterUrl.path})` }} />
					) : (
						<div className="poster" style={{ backgroundImage: `url(${defaultImage})` }} />
					)}
				<div className="overlay" />
				<div className="play" style={{ backgroundImage: `url(${iconPlay})` }} />
				{testimonial.title
				&& (
					<h4 className="card-title">{testimonial.title}</h4>
				)}
				{testimonial.subtitle
				&& (
					<h5 className="card-subtitle">{testimonial.subtitle}</h5>
				)}

			</div>
		);
	}
}

export default TestimonialCard;
